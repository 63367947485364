body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(33,45,69,255) !important;
  color: white !important;
  width: 100% !important;
  height: 100vh;
  overflow: hidden !important;
  position: relative;
}

canvas#audio-canvas {
  width: 100% !important;
  position: fixed;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
}

@media ( min-width: 992px ) {
  canvas#audio-canvas {
    width: 500px !important;
    position: fixed;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
  }
}

/* #background {
  width: 100%;
  height: 100%;
  background-image: url('./media/img23.png');
  background-size: cover;
  background-position: center;
} */

/* #noDisplay {
  display: block;
} */

.wrapper-player {
  height: 427.7px !important;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #212529; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #6c757d; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0275d8; 
}

audio {
  width: 90%;
}

audio::-webkit-media-controls-enclosure {
  color: white;
  background: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#spin {
  animation: spin 3s linear infinite;
}

@media (max-width: 991px) {

  #noDisplay {
    display: none !important;
  }
  .img-peque {
    width: 215px;
    height: 215px;
    object-fit: cover;
  }
}

@media (max-width: 991px) {

  #noDisplay {
    display: none !important;
  }
  .img-med {
    width: 350px;
    height: 350px;
    object-fit: cover;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.infinity {
  width: 200px;
  height: 100px;
  position: relative;
}

.infinity:after {
  content: "";
  width: 100px;
  height: 200px;
  border: 2px solid black;
  border-top: none;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.ant {
  width: 20px;
  height: 20px;
  background-color: red;
  position: absolute;
  top: 50%;
  left: -10px;
  animation: antMove 4s linear infinite;
}

@keyframes antMove {
  0% {
    top: 50%;
    left: -10px;
  }
  25% {
    top: -10px;
    left: 50%;
  }
  50% {
    top: 50%;
    left: calc(100% + 10px);
  }
  75% {
    top: calc(100% + 10px);
    left: 50%;
  }
  100% {
    top: 50%;
    left: -10px;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading {
  font-size: 50px;
  line-height: 1;
  animation: spin 2s linear infinite;
}
